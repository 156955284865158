import { useEffect, useState } from "react"
import {getAuth, onAuthStateChanged} from "firebase/auth"


const useAuthStatus = () =>{

    const[loggedIn, setLoggedIn] = useState(false)

    //checkingsStatus is the same as loading
    const [checkingStatus, setCheckingStatus] = useState(true)

    useEffect(()=>{
        const auth = getAuth()
        onAuthStateChanged(auth, (user)=>{
            if(user){
                setLoggedIn(true)
            }
            setCheckingStatus(false)
        })
    })

    return {loggedIn, checkingStatus}
}

export default useAuthStatus